var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getMeasurementsEnrollmentIndexRoute, MeasurementRoutes } from '~/components/routing/Routes';
import DeviceDataHistoryPage from '~/pages/device-data/DeviceDataHistoryPage';
import EnrollmentDeviceDataPage from '~/pages/device-data/EnrollmentDeviceDataPage';
import EnterDeviceDataPage from '~/pages/device-data/EnterDeviceDataPage';
import { injectAccountStore } from '~/stores/AccountStore';
import DeviceDataStore from '~/stores/DeviceDataStore';
var DeviceDataIndexPage = /** @class */ (function (_super) {
    __extends(DeviceDataIndexPage, _super);
    function DeviceDataIndexPage(props) {
        var _this = _super.call(this, props) || this;
        _this.measurementStore = new DeviceDataStore(props.accountStore);
        return _this;
    }
    DeviceDataIndexPage.prototype.render = function () {
        var accountDetail = this.props.accountStore.accountDetail;
        return (React.createElement(Provider, { measurementStore: this.measurementStore },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: MeasurementRoutes.DEVICE_DATA_INDEX_ROUTE },
                    React.createElement(Redirect, { to: getMeasurementsEnrollmentIndexRoute(accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.enrollments[0].id) })),
                React.createElement(Route, { exact: true, path: MeasurementRoutes.DEVICE_DATA_ENROLLMENT_INDEX_ROUTE },
                    React.createElement(EnrollmentDeviceDataPage, null)),
                React.createElement(Route, { exact: true, path: MeasurementRoutes.ENTER_DEVICE_DATA_ROUTE },
                    React.createElement(EnterDeviceDataPage, null)),
                React.createElement(Route, { exact: true, path: MeasurementRoutes.DEVICE_DATA_HISTORY_ROUTE },
                    React.createElement(DeviceDataHistoryPage, null)))));
    };
    DeviceDataIndexPage = __decorate([
        injectAccountStore,
        observer
    ], DeviceDataIndexPage);
    return DeviceDataIndexPage;
}(Component));
export default DeviceDataIndexPage;
