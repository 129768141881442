import * as Sentry from '@sentry/browser';
import { CaptureConsole, RewriteFrames } from '@sentry/integrations';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-lato';
import 'typeface-montserrat';
import 'whatwg-fetch';
import config from '~/config';
import App from './App';
Sentry.init({
    release: config.release,
    environment: config.environment,
    dsn: config.sentryDsn,
    integrations: [
        new RewriteFrames({
            root: globalThis.__rootdir__
        }),
        new CaptureConsole({
            levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
        })
    ],
    debug: false
});
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)), document.getElementById('root'));
