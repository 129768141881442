import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatTime } from '@cros/shared/utils/dateUtils';
import { createStyles, Grid, withStyles } from '@material-ui/core';
import React from 'react';
import DeviceDataDisplay from '~/components/misc/DeviceDataDisplay';
var styles = function (theme) {
    var _a;
    return createStyles({
        item: (_a = {
                padding: '15px 25px',
                backgroundColor: ColorsEnum.White,
                borderColor: 'rgba(0,0,0,0.12)',
                borderWidth: function (props) { return "" + (props.bordered ? '1px' : '0px'); },
                borderStyle: 'solid',
                borderTopWidth: function (props) { return "" + (props.bordered ? '0px' : undefined); },
                position: 'relative',
                '&:nth-of-type(2)': {
                    borderTop: "1px solid rgba(0,0,0,0.12)"
                }
            },
            _a[theme.breakpoints.down('xs')] = {
                padding: '10px 5px 10px 15px',
                borderWidth: '0px!important',
                marginBottom: function (props) { return "" + (props.bordered ? '10px' : undefined); }
            },
            _a)
    });
};
var DeviceDataDisplays = function (props) {
    var _a;
    var classes = props.classes, datapoint = props.datapoint, device = props.device, type = props.type;
    return (React.createElement(Grid, { container: true, alignItems: "stretch", className: classes.item }, datapoint && (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true },
            React.createElement("div", { style: {
                    backgroundColor: (_a = datapoint.getCategory(device)) === null || _a === void 0 ? void 0 : _a.color,
                    width: '6px',
                    marginRight: '15px',
                    height: '100%'
                } })),
        React.createElement(Grid, { item: true, style: { flex: '1' } },
            React.createElement("div", null,
                React.createElement(RdiTypography, { variant: "subtitle2" },
                    type === 'time' && React.createElement(React.Fragment, null, formatTime(datapoint.timestamp)),
                    type === 'days_ago' && (React.createElement(React.Fragment, null,
                        datapoint.daysAgo === 0 && "Today",
                        datapoint.daysAgo !== 0 &&
                            datapoint.daysAgo + " day" + (datapoint.daysAgo === 1 ? '' : 's') + " ago")))),
            React.createElement(Grid, { container: true, spacing: 1, wrap: "nowrap" }, (device === null || device === void 0 ? void 0 : device.schemaAll) &&
                device.schemaAll.map(function (s, schemaItemIndex) {
                    if (s.shortLabel === 'Category') {
                        return null;
                    }
                    return (React.createElement(Grid, { item: true, xs: true, key: schemaItemIndex },
                        React.createElement(DeviceDataDisplay, { key: schemaItemIndex, datapoint: datapoint, schemaItem: s })));
                })))))));
};
DeviceDataDisplays.defaultProps = {};
export default withStyles(styles)(DeviceDataDisplays);
