var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { createStyles, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import DeviceDataDisplays from '~/components/misc/DeviceDataDisplays';
import MeasurementContainer from '~/components/misc/MeasurementContainer';
import { getMeasurementHistoryRoute, getMeasureRoute } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
var styles = function (theme) {
    var _a, _b, _c, _d;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                marginBottom: '0px',
                marginTop: '0px'
            },
            _a),
        container: {
            backgroundColor: ColorsEnum.White
        },
        topLine: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _b),
        paddedMeasurementChild: (_c = {
                padding: '24px'
            },
            _c[theme.breakpoints.down('xs')] = {
                padding: '15px'
            },
            _c),
        subtitle2: {
            fontFamily: 'Montserrat',
            fontSize: '10px'
        },
        measurementLine: {
            marginTop: '0px',
            marginBottom: '0px'
        },
        measurementContainer: {},
        categoryColor: {
            width: '6px',
            marginRight: '15px!important'
        },
        daysAgo: {
            marginBottom: '15px',
            fontSize: '12px'
        },
        historyLink: {
            fontSize: '14px',
            fontFamily: 'Montserrat'
        },
        measureNowContainer: {
        // marginBottom: '15px'
        },
        measurementGridItem: (_d = {},
            _d[theme.breakpoints.down('xs')] = {
                margin: 0
            },
            _d)
    });
};
var EnrollmentDeviceDataPage = /** @class */ (function (_super) {
    __extends(EnrollmentDeviceDataPage, _super);
    function EnrollmentDeviceDataPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EnrollmentDeviceDataPage.prototype.render = function () {
        var _a = this.props, accountDetail = _a.accountStore.accountDetail, params = _a.match.params, classes = _a.classes;
        var enrollment = accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.enrollments.find(function (e) { return e.id === params.enrollmentId; });
        if (!enrollment) {
            return null;
        }
        var trial = enrollment.trial;
        return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' }, white: true },
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, trial.name),
            React.createElement(RdiLine, { className: classes.topLine }),
            React.createElement(MeasurementContainer, { bordered: true, className: classes.container },
                React.createElement("div", { className: classes.paddedMeasurementChild },
                    React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '15px' } },
                        React.createElement(RdiTypography, { variant: "h5" }, "Measurable data"),
                        React.createElement(RdiLink, { uppercase: true, className: classes.historyLink, to: getMeasurementHistoryRoute(enrollment.id) }, "History")),
                    React.createElement(RdiTypography, { variant: "body2" },
                        "Welcome to ",
                        trial.name,
                        " trial. Please make sure you complete at least two measurements per day.")),
                enrollment.devices.map(function (d) { return (React.createElement("div", { key: d.id },
                    React.createElement(RdiLine, { margin: "none" }),
                    React.createElement("div", { className: classes.paddedMeasurementChild, style: { paddingBottom: '0px' } },
                        React.createElement(FlexContainer, { justifyContent: "space-between", className: classes.measureNowContainer },
                            React.createElement(RdiTypography, { variant: "h4" }, d.label),
                            React.createElement(RdiButton, { xsMobile: true, margin: "none", style: { maxWidth: 'fit-content', fontFamily: 'Montserrat' }, to: getMeasureRoute(enrollment.id, d.id) }, "Measure now"))),
                    React.createElement(DeviceDataDisplays, { datapoint: d.latestDatapoint, device: d, type: "days_ago" }))); }),
                enrollment.devices.length === 0 && (React.createElement("div", null,
                    React.createElement(RdiLine, { margin: "none" }),
                    React.createElement("div", { className: classes.paddedMeasurementChild },
                        React.createElement(RdiTypography, { variant: "h4" }, "No devices found")))))));
    };
    EnrollmentDeviceDataPage = __decorate([
        injectAccountStore,
        observer
    ], EnrollmentDeviceDataPage);
    return EnrollmentDeviceDataPage;
}(Component));
export default withStyles(styles)(withRouter(EnrollmentDeviceDataPage));
