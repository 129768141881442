var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createStyles, withStyles } from '@material-ui/core';
import { observer, Provider } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { PatientRoutes } from '~/components/routing/Routes';
import MedicalRecordListPage from '~/pages/medical-records/MedicalRecordListPage';
import MedicalRecordPage from '~/pages/medical-records/MedicalRecordPage';
import { injectAccountStore } from '~/stores/AccountStore';
import { MedicalRecordStore } from '~/stores/MedicalRecordStore';
var styles = function () { return createStyles({}); };
var MedicalRecordIndexPage = /** @class */ (function (_super) {
    __extends(MedicalRecordIndexPage, _super);
    function MedicalRecordIndexPage(props) {
        var _this = _super.call(this, props) || this;
        _this.medicalRecordStore = new MedicalRecordStore(props.accountStore);
        return _this;
    }
    MedicalRecordIndexPage.prototype.render = function () {
        return (React.createElement(Provider, { medicalRecordStore: this.medicalRecordStore },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: PatientRoutes.MEDICAL_RECORDS_ROUTE },
                    React.createElement(MedicalRecordListPage, null)),
                React.createElement(Route, { exact: true, path: PatientRoutes.MEDICAL_RECORD_ROUTE },
                    React.createElement(MedicalRecordPage, null)))));
    };
    MedicalRecordIndexPage = __decorate([
        injectAccountStore,
        observer
    ], MedicalRecordIndexPage);
    return MedicalRecordIndexPage;
}(Component));
export default withStyles(styles)(withRouter(MedicalRecordIndexPage));
