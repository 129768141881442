import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
var styles = function (theme) {
    var _a, _b, _c;
    return createStyles({
        subtitle2: (_a = {
                fontFamily: 'Montserrat',
                fontSize: '10px'
            },
            _a[theme.breakpoints.down('xs')] = {
            // fontSize: '9px',
            },
            _a),
        measurement: (_b = {
                fontWeight: 500,
                marginRight: '8px!important'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginRight: '6px!important',
                fontSize: '24px'
            },
            _b),
        unit: (_c = {},
            _c[theme.breakpoints.down('xs')] = {
            // fontFamily: 'Lato'
            // fontSize: '8px',
            // letterSpacing: '-1px',
            },
            _c)
    });
};
var DeviceDataDisplay = function (props) {
    var classes = props.classes, datapoint = props.datapoint, schemaItem = props.schemaItem;
    return (React.createElement(FlexContainer, null,
        React.createElement(RdiTypography, { variant: "h1", className: classes.measurement }, datapoint.fieldsAll[schemaItem.slug]),
        React.createElement("div", null,
            React.createElement(RdiTypography, { variant: "subtitle2", className: classes.subtitle2 }, schemaItem.shortLabel),
            React.createElement(RdiTypography, { variant: "subtitle2", className: classes.subtitle2 + " " + classes.unit }, schemaItem.unit))));
};
DeviceDataDisplay.defaultProps = {};
export default withStyles(styles)(DeviceDataDisplay);
