var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography, { TypographyColorsEnum } from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SmallModal from '@cros/shared/components/modals/SmallModal';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { utilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import visitService from '~/services/VisitService';
import { injectAccountStore } from '~/stores/AccountStore';
var styles = function (theme) {
    var _a, _b, _c, _d, _e;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                marginBottom: '0px',
                marginTop: '0px'
            },
            _a),
        container: (_b = {
                backgroundColor: ColorsEnum.White,
                marginBottom: '5px'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginBottom: '0px',
                borderWidth: '0px',
                height: '100%'
            },
            _b),
        topLine: (_c = {},
            _c[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _c),
        backLink: (_d = {},
            _d[theme.breakpoints.up('sm')] = {
                display: 'none'
            },
            _d.margin = '5px 0px 20px 0px',
            _d.marginLeft = '-5px',
            _d),
        submitButtonContainer: (_e = {
                width: 'calc(50% - 15px)'
            },
            _e[theme.breakpoints.down('xs')] = {
                width: '100%',
                height: '100%'
            },
            _e)
    });
};
var initialState = {
    date: {
        value: new Date(),
        label: '',
        error: null,
        rule: 'required'
    },
    trial: {
        value: '',
        label: '',
        error: null,
        rule: 'required'
    }
};
var BookVisit = function (_a) {
    var _b = _a.accountStore, accountDetail = _b.accountDetail, authToken = _b.authToken, classes = _a.classes;
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), hasShowModal = _d[0], setShowModal = _d[1];
    var _e = useState(initialState), data = _e[0], setData = _e[1];
    var trials = accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.enrollments.map(function (e) { return ({
        label: e.trial.name,
        value: e.trial.id
    }); });
    var onFieldChange = function (e, field) {
        setData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[field] = {
                value: e.value,
                label: e.label
            }, _a)));
        });
    };
    var onClose = function () {
        setShowModal(function (prevState) { return !prevState; });
    };
    var validateForm = function (validationErrors) {
        return utilValidate(data, null, validationErrors, true);
    };
    var submit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var hasErrors, _a, _b, _c, error_1;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    hasErrors = validateForm();
                    if (hasErrors) {
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 4, 5, 6]);
                    _b = (_a = visitService).bookVisit;
                    _c = [data.date.value, data.trial.value];
                    return [4 /*yield*/, authToken()];
                case 2: return [4 /*yield*/, _b.apply(_a, _c.concat([_d.sent()]))];
                case 3:
                    _d.sent();
                    // reset form
                    setShowModal(true);
                    setData(initialState);
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _d.sent();
                    hasErrors = validateForm(error_1.meta);
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var history = useHistory();
    return (React.createElement(React.Fragment, null,
        React.createElement(RdiLink, { onClick: function () { return history.goBack(); }, startIcon: React.createElement(KeyboardArrowLeft, null), className: classes.backLink }, "Back"),
        React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Book visit"),
        React.createElement(RdiTypography, { variant: "body2", color: TypographyColorsEnum.Black, style: { marginBottom: '20px' } }, "Specify date when you are available for a visit."),
        React.createElement(RdiLine, { className: classes.topLine }),
        React.createElement("form", { onSubmit: submit, style: { height: '100%' } },
            React.createElement("div", { className: classes.container, style: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '15px'
                } },
                React.createElement(RdiInput, { type: "date", label: "Visit date", name: "date", value: data.date.value, errorText: data.date.error, onChange: function (e) { return onFieldChange(e, 'date'); }, style: { width: 'calc(50% - 15px)' } }),
                React.createElement(RdiInput, { type: "select", label: "Trial", placeholder: "Select trial", name: "trial", selectedItemLabel: data.trial.label, value: data.trial.value, errorText: data.trial.error, onChange: function (e) { return onFieldChange(e, 'trial'); }, style: { width: 'calc(50% - 15px)' }, options: trials }),
                React.createElement(FlexContainer, { alignItems: "flex-end", className: classes.submitButtonContainer },
                    React.createElement(RdiButton, { loading: isLoading, submit: true, fullWidth: true }, "Submit")))),
        React.createElement(SmallModal, { open: hasShowModal, onClose: onClose, style: { height: '25%' } },
            React.createElement(RdiTypography, { variant: "h2", className: classes.title }, "Success"),
            React.createElement("br", null),
            React.createElement(RdiTypography, { variant: "body1" }, "Visit booked!"),
            React.createElement(RdiTypography, { variant: "body2" }, "The clinic will contact you to arrange a time on the provided date."))));
};
export default withStyles(styles)(injectAccountStore(observer(BookVisit)));
