var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccountRoutes as SharedAccountRoutes } from '@cros/shared/components/routing/AccountRoutes';
import { PublicRoutes as SharedPublicRoutes } from '@cros/shared/components/routing/PublicRoutes';
export var AccountRoutes = __assign(__assign({}, SharedAccountRoutes), { CHANGE_PASSWORD_ROUTE: '/change-password' });
export var PublicRoutes = __assign({}, SharedPublicRoutes);
export var PatientRoutes = {
    MEDICAL_RECORDS_ROUTE: '/medical-records',
    MEDICAL_RECORD_ROUTE: '/medical-records/:id',
    BOOK_VISIT_ROUTE: '/book-visit',
    CLINIC_DOCUMENTS_ROUTE: '/clinic-documents',
    MESSAGING_ROUTE: '/messaging',
    MESSAGING_THERAD_ROUTE: '/messaging/thread/:id'
};
export var MeasurementRoutes = {
    DEVICE_DATA_INDEX_ROUTE: '/device-data',
    DEVICE_DATA_ENROLLMENT_INDEX_ROUTE: '/device-data/:enrollmentId',
    ENTER_DEVICE_DATA_ROUTE: '/device-data/:enrollmentId/enter/:deviceId',
    DEVICE_DATA_HISTORY_ROUTE: '/device-data/:enrollmentId/history'
};
export var getMeasurementsEnrollmentIndexRoute = function (enrollmentId) { return "/device-data/" + enrollmentId; };
export var getMeasureRoute = function (enrollmentId, deviceId) {
    return "/device-data/" + enrollmentId + "/enter/" + deviceId;
};
export var getMeasurementHistoryRoute = function (enrollmentId) { return "/device-data/" + enrollmentId + "/history"; };
export var getMedicalRecordsRoute = function () { return "/medical-records"; };
export var getMessagingThreadRoute = function (taskId) { return "/messaging/thread/" + taskId; };
