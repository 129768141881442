var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiListItem from '@cros/shared/components/misc/RdiListItem';
import { PublicRoutes } from '@cros/shared/components/routing/PublicRoutes';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import SizingEnum from '@cros/shared/constants/styling/SizingEnum';
import { IconButton, List, ListItemText } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer } from 'mobx-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AccountRoutes, getMeasurementsEnrollmentIndexRoute, PatientRoutes } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
var styles = function (theme) {
    var _a;
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: SizingEnum.drawerWidth
        },
        // necessary for content to be below app bar
        navLinkActive: {
            backgroundColor: "" + ColorsEnum.BlueDark,
            '&:hover': {
                backgroundColor: "rgba(41,96,148,0.91)"
            },
            '& span': {
                color: 'white !important'
            },
            '& svg path': {
                stroke: 'white',
                strokeOpacity: 1
            },
            '& .circle': {
                backgroundColor: ColorsEnum.White,
                color: ColorsEnum.BlueDark
            }
        },
        accountButton: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '33px',
            padding: '0px',
            marginBottom: '0px'
        },
        names: {
            flex: '1',
            alignItems: 'flex-start',
            textAlign: 'left',
            paddingLeft: '25px',
            maxWidth: '100%',
            wordBreak: 'break-word'
        },
        moreAccountContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50px',
            opacity: 0.25
        },
        links: {
            marginTop: '30px',
            flex: '1'
        },
        logo: {
            marginLeft: '25px',
            marginTop: '33px',
            width: '94px'
        },
        navFooter: {
            marginLeft: '25px',
            marginRight: '25px',
            marginBottom: '20px',
            '& >div': {
                marginTop: '20px'
            }
        },
        navFooterLinks: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: '10px'
        },
        closeIcon: (_a = {},
            _a[theme.breakpoints.up('md')] = {
                display: 'none'
            },
            _a)
    });
};
var NavigationDrawer = function (props) {
    var logoutClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accountStore;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accountStore = props.accountStore;
                    return [4 /*yield*/, accountStore.logout()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var accountDetail = props.accountStore.accountDetail, classes = props.classes, handleDrawerToggle = props.handleDrawerToggle;
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.navFooter, style: { marginBottom: '0px' } },
            React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '10px' } },
                React.createElement(RdiTypography, { variant: "body2" }, accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.fullName),
                React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", edge: "start", onClick: handleDrawerToggle, size: "small", className: classes.closeIcon },
                    React.createElement(CloseIcon, null))),
            React.createElement(RdiLink, { xs: true, to: AccountRoutes.CHANGE_PASSWORD_ROUTE, onClick: handleDrawerToggle, style: { marginTop: '15px' } }, "CHANGE PASSWORD"),
            React.createElement(RdiLink, { xs: true, to: PatientRoutes.MEDICAL_RECORDS_ROUTE, onClick: handleDrawerToggle, style: { marginTop: '15px' } }, "MEDICAL RECORDS"),
            React.createElement(RdiLink, { xs: true, to: PatientRoutes.BOOK_VISIT_ROUTE, onClick: handleDrawerToggle, style: { marginTop: '15px' } }, "BOOK VISIT"),
            React.createElement(RdiLink, { xs: true, to: PatientRoutes.CLINIC_DOCUMENTS_ROUTE, onClick: handleDrawerToggle, style: { marginTop: '15px' } }, "CLINIC DOCUMENTS"),
            React.createElement(RdiLink, { xs: true, to: PatientRoutes.MESSAGING_ROUTE, onClick: handleDrawerToggle, style: { marginTop: '15px' } }, "MESSAGING")),
        React.createElement("div", { className: classes.links },
            React.createElement(List, null, accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.enrollments.map(function (e) {
                var _a;
                return (React.createElement(RdiListItem, { key: e.id, button: true, component: NavLink, to: getMeasurementsEnrollmentIndexRoute(e.id), activeClassName: classes.navLinkActive, onClick: handleDrawerToggle },
                    React.createElement(ListItemText, { primaryTypographyProps: { variant: 'body2' } }, (_a = e.trial) === null || _a === void 0 ? void 0 : _a.name)));
            })),
            React.createElement(RdiLine, null),
            React.createElement("div", { className: classes.navFooter },
                React.createElement(RdiLink, { xs: true, style: { display: 'flex', alignItems: 'center' }, onClick: function (e) {
                        handleDrawerToggle(e);
                        logoutClick();
                    } },
                    React.createElement(ExitToAppIcon, { style: { marginRight: '7px' } }),
                    "LOG OUT"))),
        React.createElement("div", { className: classes.navFooter },
            React.createElement("div", null,
                React.createElement(RdiLink, { to: PublicRoutes.PRIVACY }, "Privacy")),
            React.createElement("div", null,
                React.createElement(RdiLink, { to: PublicRoutes.TERMS }, "Terms")),
            React.createElement("div", null,
                React.createElement(RdiLink, { to: "#" }, "Contact us")),
            React.createElement("div", { style: { marginTop: '30px' } },
                React.createElement(RdiTypography, { variant: "caption" },
                    React.createElement("span", { style: { fontSize: '13px' } }, "\u00A9"),
                    " \u00A0 2022 MediSci")))));
};
export default withStyles(styles)(injectAccountStore(observer(NavigationDrawer)));
