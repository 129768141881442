var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { MedicalRecordTypeLabelsEnum } from '@cros/shared/constants/enums/MedicalRecordTypeEnum';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { CircularProgress, createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MeasurementContainer from '~/components/misc/MeasurementContainer';
import { PatientRoutes } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectMedicalRecordStore } from '~/stores/MedicalRecordStore';
var styles = function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return createStyles({
        title: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                marginBottom: '0px',
                marginTop: '0px'
            },
            _a),
        container: (_b = {
                backgroundColor: ColorsEnum.White,
                marginBottom: '5px'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginBottom: '0px',
                borderWidth: '0px'
            },
            _b),
        topLine: (_c = {},
            _c[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _c),
        paddedMeasurementChild: (_d = {
                padding: '24px'
            },
            _d[theme.breakpoints.down('xs')] = {
                padding: '15px'
            },
            _d),
        subtitle2: {
            fontFamily: 'Montserrat',
            fontSize: '10px'
        },
        measurementLine: {
            marginTop: '0px',
            marginBottom: '0px'
        },
        measurementContainer: {},
        categoryColor: {
            width: '6px',
            marginRight: '15px!important'
        },
        daysAgo: {
            marginBottom: '15px',
            fontSize: '12px'
        },
        historyLink: {
            fontSize: '14px',
            fontFamily: 'Montserrat'
        },
        measureNowContainer: {
        // marginBottom: '15px'
        },
        measurementGridItem: (_e = {},
            _e[theme.breakpoints.down('xs')] = {
                margin: 0
            },
            _e),
        backLink: (_f = {
                fontFamily: 'Montserrat',
                marginLeft: '-5px',
                marginTop: '0px',
                marginBottom: '20px'
            },
            _f[theme.breakpoints.up('md')] = {
                display: 'none'
            },
            _f)
    });
};
var MedicalRecordListPage = /** @class */ (function (_super) {
    __extends(MedicalRecordListPage, _super);
    function MedicalRecordListPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var changeMedicalRecordFilters, options, intersectionObserver;
            return __generator(this, function (_a) {
                changeMedicalRecordFilters = this.props.medicalRecordStore.changeMedicalRecordFilters;
                changeMedicalRecordFilters({});
                options = {
                    root: null,
                    rootMargin: '20px',
                    threshold: 1.0
                };
                intersectionObserver = new IntersectionObserver(this.handleObserver, options);
                intersectionObserver.observe(this.loadingRef);
                return [2 /*return*/];
            });
        }); };
        _this.handleObserver = function (entities) {
            var _a = _this.props.medicalRecordStore, changeMedicalRecordFilters = _a.changeMedicalRecordFilters, medicalRecordFilters = _a.medicalRecordFilters, medicalRecordsLoading = _a.medicalRecordsLoading;
            if (medicalRecordsLoading) {
                return;
            }
            var target = entities[0];
            if (target.isIntersecting) {
                changeMedicalRecordFilters({ page: medicalRecordFilters.page + 1 });
            }
        };
        _this.goToDetail = function (medicalRecord) {
            var history = _this.props.history;
            history.push(PatientRoutes.MEDICAL_RECORDS_ROUTE + "/" + medicalRecord.id);
        };
        return _this;
    }
    MedicalRecordListPage.prototype.render = function () {
        var _this = this;
        var classes = this.props.classes;
        var _a = this.props.medicalRecordStore, medicalRecordsLoading = _a.medicalRecordsLoading, medicalRecords = _a.medicalRecords, totalItems = _a.totalItems;
        return (React.createElement(FixedWidthContent, { style: { maxWidth: '780px' }, white: true },
            React.createElement(RdiLink, { to: "/", className: classes.backLink, startIcon: React.createElement(KeyboardArrowLeft, null) }, "Back"),
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "Medical records"),
            React.createElement(RdiLine, { className: classes.topLine }),
            React.createElement("div", { style: { paddingBottom: '20px' } }, medicalRecords.map(function (medicalRecord) { return (React.createElement(MeasurementContainer, { bordered: true, className: classes.container, onClick: function () { return _this.goToDetail(medicalRecord); } },
                React.createElement("div", { className: classes.paddedMeasurementChild },
                    React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '15px' }, alignItems: "flex-start", margin: 10 },
                        React.createElement(RdiTypography, { variant: "h4" }, medicalRecord.title),
                        React.createElement(RdiTypography, { variant: "subtitle2", style: { minWidth: '100px', paddingTop: '2px' } }, formatDate(medicalRecord.sharedToPatientAt))),
                    React.createElement(RdiTypography, { variant: "body2" }, MedicalRecordTypeLabelsEnum[medicalRecord.type])))); })),
            React.createElement("div", { ref: function (loadingRef) { return (_this.loadingRef = loadingRef); } }, (medicalRecordsLoading || totalItems > medicalRecords.length) && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null))))));
    };
    MedicalRecordListPage = __decorate([
        injectAccountStore,
        injectMedicalRecordStore,
        observer
    ], MedicalRecordListPage);
    return MedicalRecordListPage;
}(Component));
export default withStyles(styles)(withRouter(MedicalRecordListPage));
