var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import PageContent from '@cros/shared/components/layout/content/PageContent';
import NavigationDrawerWrapper from '@cros/shared/components/layout/NavigationDrawerWrapper';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import NavigationDrawer from '~/components/layout/NavigationDrawer';
import { AccountRoutes, MeasurementRoutes, PatientRoutes } from '~/components/routing/Routes';
import ChangePasswordPage from '~/pages/account/ChangePasswordPage';
import DeviceDataIndexPage from '~/pages/device-data/DeviceDataIndexPage';
import MedicalRecordIndexPage from '~/pages/medical-records/MedicalRecordIndexPage';
import BookVisitPage from './book-visit/BookVisitPage';
import ClinicDocumentsPage from './clinic-documents/ClinicDocumentsPage';
import MessagingPage from './messaging/MessagingPage';
import ThreadPage from './messaging/ThreadPage';
var styles = function () { return createStyles({}); };
var LoggedInPage = /** @class */ (function (_super) {
    __extends(LoggedInPage, _super);
    function LoggedInPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoggedInPage.prototype.render = function () {
        return (React.createElement(PageContent, null,
            React.createElement(NavigationDrawerWrapper, { NavigationDrawer: NavigationDrawer }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: MeasurementRoutes.DEVICE_DATA_INDEX_ROUTE },
                    React.createElement(DeviceDataIndexPage, null)),
                React.createElement(Route, { path: PatientRoutes.MEDICAL_RECORDS_ROUTE },
                    React.createElement(MedicalRecordIndexPage, null)),
                React.createElement(Route, { exact: true, path: AccountRoutes.CHANGE_PASSWORD_ROUTE },
                    React.createElement(ChangePasswordPage, null)),
                React.createElement(Route, { exact: true, path: PatientRoutes.BOOK_VISIT_ROUTE },
                    React.createElement(BookVisitPage, null)),
                React.createElement(Route, { exact: true, path: PatientRoutes.CLINIC_DOCUMENTS_ROUTE },
                    React.createElement(ClinicDocumentsPage, null)),
                React.createElement(Route, { exact: true, path: PatientRoutes.MESSAGING_ROUTE },
                    React.createElement(MessagingPage, null)),
                React.createElement(Route, { exact: true, path: PatientRoutes.MESSAGING_THERAD_ROUTE },
                    React.createElement(ThreadPage, null)))));
    };
    LoggedInPage = __decorate([
        observer
    ], LoggedInPage);
    return LoggedInPage;
}(Component));
export default withStyles(styles)(LoggedInPage);
