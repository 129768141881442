var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CenteredContent from '@cros/shared/components/layout/content/CenteredContent';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import NoMeasurements from '@cros/shared/static/NoMeasurements.svg';
import { formatDateLong, getZeroPaddedDay } from '@cros/shared/utils/dateUtils';
import { AppBar, CircularProgress, createStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { getYear } from 'date-fns';
import { groupBy } from 'lodash';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import DeviceDataDisplays from '~/components/misc/DeviceDataDisplays';
import MeasurementContainer from '~/components/misc/MeasurementContainer';
import { getMeasurementsEnrollmentIndexRoute } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import DeviceDataStore from '~/stores/DeviceDataStore';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        backLink: {
            fontFamily: 'Montserrat',
            marginLeft: '-5px',
            marginTop: '0px'
        },
        tab: {
            textTransform: 'none'
        },
        time: (_a = {
                margin: '40px 0px 15px 0px'
            },
            _a[theme.breakpoints.down('xs')] = {
                margin: '25px 0px 15px 0px'
            },
            _a),
        tabs: {
            marginBottom: '0px'
        },
        title: (_b = {
                marginTop: '15px'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginBottom: '15px'
            },
            _b)
    });
};
var DeviceDataHistoryPage = /** @class */ (function (_super) {
    __extends(DeviceDataHistoryPage, _super);
    function DeviceDataHistoryPage(props) {
        var _this = _super.call(this, props) || this;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var options, intersectionObserver;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.deviceDataStore.setSelectedDevice(this.enrollment, this.enrollment.historyDeviceTypes[0])];
                    case 1:
                        _a.sent();
                        options = {
                            root: null,
                            rootMargin: '20px',
                            threshold: 1.0
                        };
                        intersectionObserver = new IntersectionObserver(this.handleObserver, options);
                        intersectionObserver.observe(this.loadingRef);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.changeSelectedDevice = function (e, id) {
            var device = _this.enrollment.historyDeviceTypes.find(function (d) { return d.id === id; });
            _this.deviceDataStore.setSelectedDevice(_this.enrollment, device);
        };
        _this.handleObserver = function (entities) {
            var _a = _this.deviceDataStore, changeFilters = _a.changeFilters, filters = _a.filters, loading = _a.loading;
            if (loading) {
                return;
            }
            var target = entities[0];
            if (target.isIntersecting) {
                changeFilters({ page: filters.page + 1 });
            }
        };
        _this.deviceDataStore = new DeviceDataStore(props.accountStore);
        return _this;
    }
    Object.defineProperty(DeviceDataHistoryPage.prototype, "enrollment", {
        get: function () {
            var _a = this.props, accountDetail = _a.accountStore.accountDetail, params = _a.match.params;
            return accountDetail.getEnrollmentById(params.enrollmentId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeviceDataHistoryPage.prototype, "groupedData", {
        get: function () {
            var group = groupBy(this.deviceDataStore.deviceData, function (deviceData) {
                return "" + getZeroPaddedDay(deviceData.timestamp) + getYear(deviceData.timestamp);
            });
            var arr = [];
            Object.keys(group).map(function (key) {
                arr.push(group[key]);
                return key;
            });
            return arr;
        },
        enumerable: false,
        configurable: true
    });
    DeviceDataHistoryPage.prototype.render = function () {
        var _this = this;
        var classes = this.props.classes;
        var _a = this.deviceDataStore, selectedDevice = _a.selectedDevice, loading = _a.loading, count = _a.count, deviceData = _a.deviceData, filters = _a.filters;
        return (React.createElement(FixedWidthContent, null,
            React.createElement(RdiLink, { to: getMeasurementsEnrollmentIndexRoute(this.enrollment.id), className: classes.backLink, startIcon: React.createElement(KeyboardArrowLeft, null) }, "Back to trial"),
            React.createElement(RdiTypography, { variant: "h1", className: classes.title }, "History"),
            React.createElement(React.Fragment, null, selectedDevice && (React.createElement(AppBar, { position: "static", color: "default" },
                React.createElement(Tabs, { value: selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.id, onChange: this.changeSelectedDevice, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", className: classes.tabs }, this.enrollment.historyDeviceTypes.map(function (device) { return (React.createElement(Tab, { key: device.id, value: device.id, label: device.label, className: classes.tab })); }))))),
            (filters.page > 1 || !loading) && (React.createElement(MeasurementContainer, null, this.groupedData.map(function (g, index) { return (React.createElement("div", { key: index },
                React.createElement("div", { className: classes.time },
                    React.createElement(RdiTypography, { variant: "subtitle2" }, formatDateLong(g[0].timestamp))),
                g.map(function (d, inGroupIndex) { return (React.createElement(DeviceDataDisplays, { key: inGroupIndex, datapoint: d, device: selectedDevice, type: "time", bordered: true })); }))); }))),
            React.createElement("div", { ref: function (loadingRef) { return (_this.loadingRef = loadingRef); } }, (loading || count > deviceData.length) && (React.createElement(CenteredContent, null,
                React.createElement(CircularProgress, null)))),
            deviceData.length === 0 && !loading && (React.createElement(FlexContainer, { justifyContent: "center", flexDirection: "column", margin: 10, style: { marginTop: '30px' } },
                React.createElement("img", { alt: "no-measurements", src: NoMeasurements }),
                React.createElement(RdiTypography, { variant: "body2" }, "No measurements")))));
    };
    __decorate([
        computed
    ], DeviceDataHistoryPage.prototype, "enrollment", null);
    __decorate([
        computed
    ], DeviceDataHistoryPage.prototype, "groupedData", null);
    DeviceDataHistoryPage = __decorate([
        injectAccountStore,
        observer
    ], DeviceDataHistoryPage);
    return DeviceDataHistoryPage;
}(Component));
export default withStyles(styles)(withRouter(DeviceDataHistoryPage));
