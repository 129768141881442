var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import { MedicalRecordTypeLabelsEnum } from '@cros/shared/constants/enums/MedicalRecordTypeEnum';
import { formatDate } from '@cros/shared/utils/dateUtils';
import { Breadcrumbs, createStyles, Link, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MeasurementContainer from '~/components/misc/MeasurementContainer';
import { PatientRoutes } from '~/components/routing/Routes';
import config from '~/config';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectMedicalRecordStore } from '~/stores/MedicalRecordStore';
var styles = function (theme) {
    var _a, _b, _c;
    return createStyles({
        mobileNone: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _a),
        desktopNone: (_b = {},
            _b[theme.breakpoints.up('md')] = {
                display: 'none'
            },
            _b),
        backLink: (_c = {
                fontFamily: 'Montserrat',
                marginLeft: '-5px',
                marginTop: '0px'
            },
            _c[theme.breakpoints.up('md')] = {
                display: 'none'
            },
            _c)
    });
};
var MedicalRecordPage = /** @class */ (function (_super) {
    __extends(MedicalRecordPage, _super);
    function MedicalRecordPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, medicalRecordStore, params, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.props, medicalRecordStore = _a.medicalRecordStore, params = _a.match.params;
                        _b = this;
                        return [4 /*yield*/, medicalRecordStore.getMedicalRecordById(params.id)];
                    case 1:
                        _b.medicalRecord = _c.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    MedicalRecordPage.prototype.render = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var classes = this.props.classes;
        return (React.createElement(FlexContainer, { style: { width: '100%' }, flexDirection: "column", alignItems: "flex-start" },
            React.createElement(Breadcrumbs, { separator: "/", "aria-label": "breadcrumb", style: { width: '100%' }, className: classes.mobileNone },
                React.createElement(RdiLink, { to: PatientRoutes.MEDICAL_RECORDS_ROUTE, xs: true }, "Medical records"),
                React.createElement("span", { style: { color: 'black' } }, (_a = this.medicalRecord) === null || _a === void 0 ? void 0 : _a.title)),
            React.createElement(FixedWidthContent, { white: true },
                React.createElement(RdiLink, { to: PatientRoutes.MEDICAL_RECORDS_ROUTE, className: classes.backLink, startIcon: React.createElement(KeyboardArrowLeft, null) }, "Back"),
                React.createElement(MeasurementContainer, null,
                    React.createElement(FlexContainer, { justifyContent: "space-between", style: { marginBottom: '15px' }, margin: 10, alignItems: "flex-start" },
                        React.createElement(RdiTypography, { variant: "h1" }, (_b = this.medicalRecord) === null || _b === void 0 ? void 0 : _b.title),
                        React.createElement(RdiTypography, { variant: "subtitle2", style: { minWidth: '100px', lineHeight: '32px' } }, formatDate((_c = this.medicalRecord) === null || _c === void 0 ? void 0 : _c.sharedToPatientAt))),
                    React.createElement("div", { style: { paddingBottom: '10px' } },
                        React.createElement(RdiTypography, { variant: "body2" }, MedicalRecordTypeLabelsEnum[(_d = this.medicalRecord) === null || _d === void 0 ? void 0 : _d.type])),
                    React.createElement(RdiLine, { xs: true, className: classes.mobileNone }),
                    false && (React.createElement(React.Fragment, null,
                        React.createElement(RdiTypography, { variant: "subtitle2" }, "DESCRIPTION"),
                        React.createElement(RdiTypography, { variant: "body2" }, (_e = this.medicalRecord) === null || _e === void 0 ? void 0 : _e.title))),
                    React.createElement(RdiTypography, { variant: "subtitle2", style: { marginBottom: '10px', paddingTop: '10px' } }, "FILES"),
                    React.createElement(RdiTypography, { variant: "body2" },
                        "Shared on ",
                        formatDate((_f = this.medicalRecord) === null || _f === void 0 ? void 0 : _f.sharedToPatientAt)),
                    React.createElement(RdiLine, { md: true }), (_g = this.medicalRecord) === null || _g === void 0 ? void 0 :
                    _g.records.map(function (file) { return (React.createElement(React.Fragment, null,
                        React.createElement(FlexContainer, { justifyContent: "space-between" },
                            React.createElement(Link, { target: "_blank", href: "" + (config === null || config === void 0 ? void 0 : config.apiUrl) + file.url, style: { maxWidth: '70%', wordBreak: 'break-all' } }, file.originalname),
                            React.createElement("span", null, file.sizeString)),
                        React.createElement(RdiLine, { md: true }))); })))));
    };
    __decorate([
        observable
    ], MedicalRecordPage.prototype, "medicalRecord", void 0);
    MedicalRecordPage = __decorate([
        injectAccountStore,
        injectMedicalRecordStore,
        observer
    ], MedicalRecordPage);
    return MedicalRecordPage;
}(Component));
export default withStyles(styles)(withRouter(MedicalRecordPage));
