var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AccountRoutes } from '@cros/shared/components/routing/AccountRoutes';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { MeasurementRoutes } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import LoginPage from './LoginPage';
import NewPasswordRequiredPage from './NewPasswordRequiredPage';
import ResetPasswordPage from './ResetPasswordPage';
import SetNewPasswordPage from './SetNewPasswordPage';
var AccountPage = /** @class */ (function (_super) {
    __extends(AccountPage, _super);
    function AccountPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccountPage.prototype.render = function () {
        var _a = this.props.accountStore, LoginState = _a.LoginState, confirmPasswordChangeParams = _a.confirmPasswordChangeParams;
        return (React.createElement(React.Fragment, null,
            LoginState === 'LOGGED_IN' && (React.createElement(Route, { exact: true, path: AccountRoutes.ACCOUNT_ROUTE },
                React.createElement(Redirect, { to: MeasurementRoutes.DEVICE_DATA_INDEX_ROUTE }))),
            LoginState === 'PASSWORD_CHANGE_REQUIRED' && !!confirmPasswordChangeParams && (React.createElement(Redirect, { to: AccountRoutes.NEW_PASSWORD_REQUIRED_ROUTE })),
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: AccountRoutes.LOGIN_ROUTE, component: LoginPage }),
                React.createElement(Route, { exact: true, path: AccountRoutes.RESET_PASSWORD, component: ResetPasswordPage }),
                React.createElement(Route, { exact: true, path: AccountRoutes.NEW_PASSWORD_REQUIRED_ROUTE, component: NewPasswordRequiredPage }),
                React.createElement(Route, { exact: true, path: AccountRoutes.SET_NEW_PASSWORD, component: SetNewPasswordPage }),
                React.createElement(Route, { exact: true, path: "*" }, LoginState === 'LOGGED_OUT' && React.createElement(Redirect, { to: AccountRoutes.LOGIN_ROUTE })))));
    };
    AccountPage = __decorate([
        injectAccountStore,
        observer
    ], AccountPage);
    return AccountPage;
}(Component));
export default AccountPage;
