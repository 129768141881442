import { createStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
var styles = function () {
    return createStyles({
        root: {
            width: '645px',
            maxWidth: '100%',
            border: function (props) {
                return (props.bordered ? '1' : '0') + "px solid rgba(0,0,0,0.12)";
            },
            margin: '10px auto 20px auto',
            cursor: function (props) { return (props.onClick ? 'pointer' : undefined); }
        }
    });
};
var MeasurementContainer = function (props) {
    return (React.createElement("div", { style: props.style, className: clsx(props.classes.root, props.className), onClick: props.onClick, role: props.onClick ? 'button' : undefined, tabIndex: props.onClick ? 0 : undefined }, props.children));
};
MeasurementContainer.defaultProps = {};
export default withStyles(styles)(MeasurementContainer);
