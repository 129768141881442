var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import RdiButton from '@cros/shared/components/forms/RdiButton';
import RdiInput from '@cros/shared/components/forms/RdiInput';
import FixedWidthContent from '@cros/shared/components/layout/content/FixedWidthContent';
import RdiLine from '@cros/shared/components/layout/RdiLine';
import RdiTypography from '@cros/shared/components/layout/RdiTypography';
import FlexContainer from '@cros/shared/components/misc/FlexContainer';
import SuccessBox from '@cros/shared/components/misc/SuccessBox';
import { AccountRoutes } from '@cros/shared/components/routing/AccountRoutes';
import RdiLink from '@cros/shared/components/routing/RdiLink';
import ColorsEnum from '@cros/shared/constants/styling/ColorsEnum';
import { newFieldChange } from '@cros/shared/utils/utils';
import { newUtilValidate } from '@cros/shared/utils/validationUtils';
import { createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MeasurementContainer from '~/components/misc/MeasurementContainer';
import { getMeasurementsEnrollmentIndexRoute } from '~/components/routing/Routes';
import { injectAccountStore } from '~/stores/AccountStore';
import { injectDeviceDataStore } from '~/stores/DeviceDataStore';
var styles = function (theme) {
    var _a, _b, _c, _d;
    return createStyles({
        backLink: (_a = {
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Montserrat',
                marginLeft: '-5px'
            },
            _a[theme.breakpoints.down('xs')] = {
                margin: '0px 0px 10px 0px'
            },
            _a),
        inputContainer: (_b = {
                marginTop: '30px'
            },
            _b[theme.breakpoints.down('xs')] = {
                marginTop: '20px'
            },
            _b),
        mobileHide: (_c = {},
            _c[theme.breakpoints.down('xs')] = {
                display: 'none'
            },
            _c),
        saveContainer: {
            marginTop: '30px'
        },
        paddedMeasurementChild: (_d = {
                padding: '20px 0px'
            },
            _d[theme.breakpoints.down('xs')] = {
                padding: '15px'
            },
            _d)
    });
};
var EnterDeviceDataPage = /** @class */ (function (_super) {
    __extends(EnterDeviceDataPage, _super);
    function EnterDeviceDataPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = {};
        _this.errors = {};
        _this.saving = false;
        _this.errorMessage = '';
        _this.hasErrors = false;
        _this.saved = false;
        _this.fieldChange = function (e, field) {
            newFieldChange(e, field, _this.data, {});
        };
        _this.submit = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var measurementStore, measurement, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        measurementStore = this.props.measurementStore;
                        e.preventDefault();
                        e.stopPropagation();
                        this.saving = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, measurementStore.createMeasurement(this.device, this.data)];
                    case 2:
                        measurement = _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        // get validation errors
                        this.errorMessage = error_1.message || error_1.detail || error_1;
                        this.hasErrors = this.validateForm(error_1.meta);
                        return [2 /*return*/];
                    case 4:
                        this.saving = false;
                        return [7 /*endfinally*/];
                    case 5:
                        if (measurement) {
                            // this.onClose();
                            this.saved = true;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.validateForm = function (validationErrors) {
            return newUtilValidate(_this.data, _this.errors, {}, null, validationErrors);
        };
        _this.onClose = function () {
            var history = _this.props.history;
            history.push(getMeasurementsEnrollmentIndexRoute(_this.enrollment.id));
        };
        return _this;
    }
    Object.defineProperty(EnterDeviceDataPage.prototype, "enrollment", {
        get: function () {
            var _a = this.props, accountDetail = _a.accountStore.accountDetail, params = _a.match.params;
            return accountDetail.getEnrollmentById(params.enrollmentId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EnterDeviceDataPage.prototype, "device", {
        get: function () {
            var params = this.props.match.params;
            return this.enrollment.getDeviceById(params.deviceId);
        },
        enumerable: false,
        configurable: true
    });
    EnterDeviceDataPage.prototype.render = function () {
        var _this = this;
        var classes = this.props.classes;
        return (React.createElement(FixedWidthContent, null,
            !this.saved && (React.createElement(React.Fragment, null,
                React.createElement(RdiLink, { to: getMeasurementsEnrollmentIndexRoute(this.enrollment.id), className: classes.backLink },
                    React.createElement(KeyboardArrowLeft, null),
                    "Back to trial"),
                React.createElement(MeasurementContainer, { style: { backgroundColor: ColorsEnum.White } },
                    React.createElement("form", { onSubmit: this.submit },
                        React.createElement(RdiTypography, { variant: "h5", style: { marginBottom: '0px' }, className: classes.paddedMeasurementChild }, this.device.label),
                        React.createElement(RdiLine, { md: true, margin: "none" }),
                        React.createElement("div", { className: classes.paddedMeasurementChild },
                            React.createElement(RdiTypography, { variant: "body2" },
                                "Please measure your ",
                                this.device.label,
                                " and enter your results in the fields below."),
                            React.createElement("div", { style: { maxWidth: '380px', width: '100%', margin: '0 auto' } }, this.device.schema.items.map(function (s, index) { return (React.createElement(FlexContainer, { justifyContent: "space-between", alignItems: "flex-start", className: classes.inputContainer, key: index },
                                React.createElement("div", null,
                                    React.createElement("div", null,
                                        React.createElement(RdiTypography, { variant: "h3" }, s.shortLabel)),
                                    React.createElement("div", null,
                                        React.createElement(RdiTypography, { variant: "subtitle1", style: {
                                                fontFamily: 'Montserrat',
                                                fontSize: '14px',
                                                lineHeight: '18px'
                                            } }, s.unit))),
                                React.createElement("div", null,
                                    React.createElement(RdiInput, { style: { width: '144px', fontSize: '24px', height: '44px' }, value: _this.data[s.slug] || null, onChange: function (e) { return _this.fieldChange(e, s.slug); }, errorText: _this.errors[s.slug], type: "number" })))); })),
                            React.createElement(RdiLine, { className: classes.mobileHide }),
                            React.createElement("div", { className: classes.saveContainer },
                                React.createElement(RdiButton, { submit: true, loading: this.saving, style: { minWidth: '280px', maxWidth: '100%', margin: '0 auto' } }, "Save"))))))),
            this.saved && (React.createElement(SuccessBox, { margin: "none", text: "You have successfully entered your measurable data.", to: AccountRoutes.ACCOUNT_ROUTE }))));
    };
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "data", void 0);
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "errors", void 0);
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "saving", void 0);
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "hasErrors", void 0);
    __decorate([
        observable
    ], EnterDeviceDataPage.prototype, "saved", void 0);
    __decorate([
        computed
    ], EnterDeviceDataPage.prototype, "enrollment", null);
    __decorate([
        computed
    ], EnterDeviceDataPage.prototype, "device", null);
    EnterDeviceDataPage = __decorate([
        injectAccountStore,
        injectDeviceDataStore,
        observer
    ], EnterDeviceDataPage);
    return EnterDeviceDataPage;
}(Component));
export default withStyles(styles)(withRouter(EnterDeviceDataPage));
